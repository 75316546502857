<script setup lang="ts">
import {useAuthStore} from '~/store/authStore'
import type {Game} from '~/types'
import {convertApiGameToLocal, localGameStore} from '~/store/localGameStore'
import {
  tabBell,
  tabListCheck,
  tabPlayerPlay,
  tabSearch,
  tabTournament,
  tabUser,
  tabWorld
} from 'quasar-extras-svg-icons/tabler-icons-v2'
import {useQuasar} from 'quasar'

const localePath = useLocalePath()
const {t, locale, locales} = useI18n()

const userStore = useAuthStore()
const gameStore = localGameStore()

const config = useRuntimeConfig()
const $q = useQuasar()

const {maximize} = inject<{ maximize: Ref<boolean>, toggleMaximize: () => void }>('toggleMaximize')!

const sideBarLinks = [
  {
    label: t('Match center'),
    icon: tabTournament,
    to: localePath('/'),
    tooltip: {
      text: t('View ongoing and upcoming tournaments live'),
    }
  },
  {
    label: t('Find tournaments'),
    icon: tabSearch,
    to: localePath('/tournaments'),
    tooltip: {
      text: t('Find tournaments to play in'),
    }
  },
  {
    label: t('Play'),
    icon: tabPlayerPlay,
    labelClass: 'font-bold',
    children: [
      {
        label: t('Play offline'),
        badge: t('Beta'),
        to: localePath('/play/offline'),
      },
      {
        label: t('Online lobby'),
        badge: t('Beta'),
        to: localePath('/play/lobby'),
      },
    ]
  },
  {
    label: t('My DartsSpace'),
    icon: tabUser,
    defaultOpen: false,
    labelClass: 'font-bold',
    children: [
      {
        label: t('Match history'),
        to: localePath('/profile/my-matches'),
        tooltip: {
          text: t('View your match history'),
        }
      },
      {
        label: t('My tournaments'),
        to: localePath('/profile/my-tournaments'),
        tooltip: {
          text: t('Your future, completed and ongoing tournaments'),
        }
      },
      {
        label: t('My Stats'),
        to: localePath('/profile/my-stats'),
        tooltip: {
          text: t('Viw your stats and progress'),
        }
      },
      {
        label: t('Settings'),
        to: localePath('/profile/settings'),
      }
    ]
  },
  {
    label: t('Manager'),
    icon: tabTournament,
    defaultOpen: false,
    labelClass: 'font-bold',
    children: [
      {
        label: t('Tournaments'),
        to: localePath('/manage/tournaments'),
      },
      {
        label: t('Events/Locations'),
        to: localePath('/manage/events-locations'),
      },
      {
        label: t('Printer settings'),
        to: localePath('/manage/printer-settings'),
      }
    ]
  }
]

onMounted(() => {
  if (userStore.user) {
    window.Echo.private('user.' + userStore.user?.id)
      .listen('.game-ready', (data: { game: Game }) => {
        $q.notify({
          message: t('Game ready'),
          caption: t('Your game is ready to start'),
        })

        gameStore.initialize(convertApiGameToLocal(data.game))
        navigateTo(localePath(`/play/online/${data.game.id}`))
      })
      .listen('.game-cancelled', () => {
        $q.notify({
          message: t('Game cancelled'),
          caption: t('A game you were in has been cancelled'),
        })

        userStore.getUser(true)
      })
      .notification((notification: { title: string, body: string }) => {
        $q.notify({
          // id: notification.id,
          message: notification.title,
          caption: notification.body,
        })

        userStore.loadFreshNotifications()
      })
  }
})

const availableLocales = computed(() => {
  return locales.value.map(locale => {
    return {
      ...locale,
      label: locale.name,
      click: () => {
        userStore.updateLocale(locale.code)
      }
    }
  })
})

const leftDrawerOpen = ref(false)

watch(maximize, () => {
  if (maximize.value) {
    leftDrawerOpen.value = false
  } else {
    if ($q.screen.gt.md) {
      leftDrawerOpen.value = true
    }
  }
})

function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

const showNotifications = ref(false)

</script>

<template>
  <QLayout view="lHh LpR lFr">
    <QHeader
      v-if="!maximize"
      class="!bg-primary-200/50 dark:!bg-gray-600/50 text-black dark:!text-white backdrop-blur rounded-b-lg shadow-md"
    >
      <QToolbar>
        <QBtn
          round
          flat
          icon="menu"
          @click="toggleLeftDrawer"
        />

        <QToolbarTitle>
          <img src="/light-logo.png" alt="DartsSpace logo" class="h-[20px] hidden dark:!visible dark:!block">
          <img src="/dark-logo.png" alt="DartsSpace logo" class="h-[20px] dark:hidden">
        </QToolbarTitle>

        <QSpace/>

        <QBtnDropdown
          v-if="$q.screen.gt.sm"
          :icon="tabWorld"
          :label="locale"
          flat
          dense
        >
          <q-list>
            <q-item
              v-for="localeInfo in availableLocales"
              :key="localeInfo.code"
              v-close-popup
              clickable
              @click="localeInfo.click"
            >
              <q-item-section>
                <q-item-label>{{ localeInfo.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </QBtnDropdown>
        <DarkModeSwitch
          v-if="$q.screen.gt.sm"
          flat
          dense
        />
        <client-only>
          <QBtn
            v-if="userStore.user"
            flat
            :icon="tabBell"
            @click="showNotifications = !showNotifications"
          >
            <QBadge v-if="(userStore.getUnreadNotificationsCount ?? 0) > 0" color="danger" floating>
              {{ userStore.getUnreadNotificationsCount }}
            </QBadge>
          </QBtn>
        </client-only>
        <UserDropdown/>
      </QToolbar>
      <slot name="header-extra"/>
    </QHeader>

    <client-only>
      <QFooter
        v-if="!maximize && $q.screen.lt.md"
        class="!bg-primary-200/70 dark:!bg-gray-600/70 text-black dark:!text-white backdrop-blur rounded-t-lg"
      >
        <QToolbar class="!px-0">
          <QTabs stretch dense no-caps class="w-full mobile-nav">
            <QRouteTab
              class="[& .q-tab__label]:!text-lg"
              :to="localePath('/')"
              :label="$t('Match center')"
              :icon="tabTournament"
            />
            <QRouteTab :to="localePath('/play')" :label="$t('Play')" :icon="tabPlayerPlay"/>
            <QRouteTab :to="localePath('/manage')" :label="$t('Manage')" :icon="tabListCheck"/>
            <QRouteTab :to="localePath('/profile')" :label="$t('Profile')" :icon="tabUser"/>
          </QTabs>
        </QToolbar>
      </QFooter>
    </client-only>

    <QDrawer
      v-model="leftDrawerOpen"
      show-if-above
      side="left"
      bordered
      :width="225"
      no-swipe-open
    >
      <QScrollArea class="fit">
        <QList dense padding>
          <QItem class="mb-2">
            <img src="/light-logo.png" alt="DartsSpace logo" class="h-[38px] hidden dark:!visible dark:!block">
            <img src="/dark-logo.png" alt="DartsSpace logo" class="h-[38px] dark:hidden">
          </QItem>
          <template v-for="(menuItem, index) in sideBarLinks" :key="index">
            <template v-if="menuItem.children">
              <QItemLabel caption class="text-right pr-3">
                {{ menuItem.label }}
              </QItemLabel>
              <QItem
                v-for="subItem in menuItem.children"
                :key="subItem.label"
                v-ripple
                dense
                clickable
                :to="subItem.to"
              >
                <q-item-section>
                  {{ subItem.label }}
                </q-item-section>
              </QItem>
            </template>

            <QItem v-if="!menuItem.children" v-ripple clickable :to="menuItem.to">
              <QItemSection v-if="menuItem.icon" side>
                <QIcon v-if="menuItem.icon" :name="menuItem.icon"/>
              </QItemSection>
              <QItemSection>
                {{ menuItem.label }}
              </QItemSection>
            </QItem>
            <QSeparator spaced size="1px"/>
          </template>
          <client-only>
            <QItem v-if="userStore.user?.is_super_admin" v-ripple clickable :to="localePath('/admin/users')">
              <QItemSection>
                Users
              </QItemSection>
            </QItem>
          </client-only>
          <Adsbygoogle class="w-[224px] min-h-[100px] max-h-[224px]" hide-unfilled/>
        </QList>
        <QList>
          <QItem>
            <div class="flex flex-col">
              <div class="flex flex-row gap-2 text-xs underline">
                <NuxtLink to="/en/privacy-policy">
                  Privacy Policy
                </NuxtLink>
                <NuxtLink to="/en/terms-of-service">
                  Terms of Service
                </NuxtLink>
              </div>
              <div class="text-xs">
                <span>v{{ config.public.version }}</span>
              </div>
            </div>
          </QItem>
        </QList>
      </QScrollArea>
    </QDrawer>

    <QPageContainer>
      <slot/>
    </QPageContainer>

    <client-only>
      <QDrawer
        v-model="showNotifications"
        side="right"
        overlay
        behavior="mobile"
        bordered
        :width="250"
      >
        <QScrollArea class="fit">
          <NotificationsSlideover/>
        </QScrollArea>
      </QDrawer>
    </client-only>
  </QLayout>
</template>
<style>
  .mobile-nav .q-tab__label {
    @apply text-[9px] max-w-full truncate;
  }

  .mobile-nav .q-tab__icon {
    @apply size-5
  }

  .mobile-nav .q-tab {
    @apply px-1
  }

  .mobile-nav .q-tabs__content {
    @apply grid grid-cols-4
  }
</style>

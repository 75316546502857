import type {PermissionState} from "@capacitor/core";
import {Capacitor} from "@capacitor/core";
import {FirebaseMessaging} from "@capacitor-firebase/messaging";
import type {GetTokenOptions} from "@firebase/messaging";
import {initializeApp} from "@firebase/app";
import type {PermissionStatus} from "@capacitor-firebase/messaging/dist/esm/definitions";


export async function checkPermissions(): Promise<PermissionState> {
    const isSupported = () =>
        'Notification' in window &&
        'serviceWorker' in navigator &&
        'PushManager' in window

    if (isSupported()) {
        const result = await FirebaseMessaging.checkPermissions();
        return result.receive;
    }

    return Promise.resolve('denied');
}

export function initializeFirebase() {
    if (Capacitor.isNativePlatform()) {
        return;
    }
    const runTimeConfig = useRuntimeConfig()
    initializeApp(runTimeConfig.public.firebase)
    initializeFirebaseMessaging()
}

export function initializeFirebaseMessaging() {
    FirebaseMessaging.addListener("notificationReceived", (_) => {
        // console.log("notificationReceived: ", {event});
    });
    FirebaseMessaging.addListener("notificationActionPerformed", (_) => {
        // console.log("notificationActionPerformed: ", {event});
    });

    if (Capacitor.getPlatform() === "web" && navigator.serviceWorker) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navigator.serviceWorker.addEventListener("message", (event: any) => {
            new Notification(event.data.notification.title, {
                body: event.data.notification.body,
            });
            // notification.onclick = (event) => {
            //     console.log("notification clicked: ", {event});
            // };
        });
    }
}

export function deleteToken() {
    return FirebaseMessaging.deleteToken();
}

export function requestPermission(): Promise<PermissionStatus> {
    return FirebaseMessaging.requestPermissions();
}

export async function getToken(): Promise<string> {
    const runTimeConfig = useRuntimeConfig()
    const options: GetTokenOptions = {
        vapidKey: runTimeConfig.public.vapidPublicKey,
    };
    if (Capacitor.getPlatform() === "web" && navigator.serviceWorker) {
        options.serviceWorkerRegistration =
            await navigator.serviceWorker.register("/firebase-messaging-sw.js");
    }
    const {token} = await FirebaseMessaging.getToken(options);
    return token;
}

<script setup lang="ts">
import {useAuthStore} from '~/store/authStore'
import type {Notification} from '~/types'
import DateTime from './common/DateTime.vue'
import {checkPermissions, getToken, initializeFirebase, requestPermission} from "~/services/notifications";
import QCardHeaderSection from "~/components/ui/QCardHeaderSection.vue";
import {Preferences} from '@capacitor/preferences';

const authStore = useAuthStore()

const $q = useQuasar()

const canAskPermission = ref(false)
const fcmToken = ref<string | null>(null)
const pushEnabled = ref(false)

watch(canAskPermission, (newValue) => {
  Preferences.set({key: 'canAskPermission', value: newValue.toString()})
})

initializeFirebase()

onMounted(() => {
  checkPermissions().then((result) => {
    if (result === "granted") {
      pushEnabled.value = true;
      getToken().then((token) => {
        fcmToken.value = token
      })
    }
  })
})

onMounted(async () => {
  checkPermissions().then(async (result) => {
    if (result === "granted") {
      subscribe()
    }
    if (result === 'prompt') {
      const {value} = await Preferences.get({key: 'canAskPermission'})
      canAskPermission.value = value !== 'false'
    }
  })
})

function subscribe(notify: boolean = false) {
  if (!authStore.user?.id) {
    console.error('Cannot subscribe to notification. Not logged in.')
    return
  }

  pushLoading.value = true
  requestPermission().then((value) => {
    if (value.receive == 'granted') {
      getToken().then((token) => {
        useAuthPost('/app/save-subscription/', {
          method: 'POST',
          body: {
            token: token,
          }
        })
          .then(function () {
            pushEnabled.value = true;
            pushLoading.value = false
            canAskPermission.value = false
            if (notify) {
              $q.notify({
                message: 'Push notifications enabled',
                color: 'positive',
                position: 'top',
                timeout: 2000,
              })
            }
          })
      }).finally(() => {
        pushLoading.value = false
      })
    }
    if (value.receive == 'denied') {
      canAskPermission.value = false
    }
  }).finally(() => {
    pushLoading.value = false
  })
}

function sendTestNotification() {
  useAuthPost('/app/test/notification', {
    method: 'POST',
  })
}

const markNotificationAsRead = async (notification: Notification) => {
  await useAuthPost('notifications/mark-as-read', {
    method: 'POST',
    body: {id: notification.id},
  })

  authStore.loadFreshNotifications()
}

const markAllNotificationsAsRead = async () => {
  await useAuthPost('notifications/mark-all-as-read', {
    method: 'POST',
  })

  authStore.loadFreshNotifications()
}

const pushLoading = ref(false)

</script>

<template>
  <div>
    <div class="!flex flex-col gap-2 flex-wrap p-4">
      <QDialog
        v-if="authStore.user"
        :model-value="canAskPermission" no-esc-dismiss no-backdrop-dismiss @close="canAskPermission = false">
        <QCard>
          <QCardHeaderSection
            :title="$t('Enable push notifications')"
            :on-close="() => canAskPermission = false"
          />
          <QCardSection>
            <strong>{{ $t('Do you want to stay up to date with your tournaments?') }} </strong>
            <br>
            {{
              $t('If you enable push notifications, you will get notified when your registration is complete, you need to play or write and other tournament related updates')
            }}
          </QCardSection>
          <QCardActions align="between">
            <QBtn
              color="negative"
              :label="$t('No')"
              @click="canAskPermission = false"
            />
            <QBtn
              :loading="pushLoading"
              color="positive"
              @click="() => subscribe(true)"
            >
              {{ $t('Enable push notifications') }}
            </QBtn>
          </QCardActions>
        </QCard>
      </QDialog>
      <QBtn
        v-if="!pushEnabled"
        :loading="pushLoading"
        color="positive"
        size="sm"
        @click="()  => subscribe(true)"
      >
        {{ $t('Enable push notifications') }}
      </QBtn>
      <template v-if="authStore.user?.is_super_admin">
        <QBtn color="positive" size="sm" @click="sendTestNotification">
          {{ $t('DEV: Send Test') }}
        </QBtn>
      </template>
      <QBtn v-if="authStore.hasUnreadNotifications" color="warning" size="sm" @click="markAllNotificationsAsRead">
        {{ $t('Mark all notifications as read') }}
      </QBtn>
    </div>
    <template v-for="notification in authStore.user?.notifications" :key="notification.id">
      <NuxtLink
        v-if="notification.data.action"
        :to="notification.data.action.url.replace(/https?:\/\/dartsspace\.(com|test)/g, '')"
        class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative"
        :class="{'opacity-50': notification.read_at !== null}"
        @click="markNotificationAsRead(notification)"
      >
        <div class="text-sm flex-1">
          <div class="flex flex-col items-start justify-between">
            <div class="flex flex-row gap-1 items-center">
              <span class="text-gray-900 dark:text-white font-medium">{{ notification.data.title }}</span>
            </div>

            <DateTime :date="notification.created_at" class="text-gray-500 dark:text-gray-400 text-xs"/>
          </div>
          <p class="text-gray-500 dark:text-gray-400">
            {{ notification.data.body }}
          </p>
        </div>
      </NuxtLink>
      <div
        v-else-if="notification.data.title"
        class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative"
      >
        <div class="text-sm flex-1">
          <div class="flex flex-col items-start justify-between">
            <div class="flex flex-row gap-1 items-center">
              <div v-if="notification.read_at === null" class="w-2 h-2 bg-red-500 rounded-full"/>
              <span class="text-gray-900 dark:text-white font-medium">{{ notification.data.title }}</span>
            </div>

            <DateTime :date="notification.created_at" class="text-gray-500 dark:text-gray-400 text-xs"/>
          </div>
          <p class="text-gray-500 dark:text-gray-400">
            {{ notification.data.body }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {useAuthStore} from '~/store/authStore'
import {tabUser} from 'quasar-extras-svg-icons/tabler-icons-v2'

const localePath = useLocalePath()

const authStore = useAuthStore()
const user = await authStore.getUser()
const $q = useQuasar()
</script>

<template>
  <q-btn-dropdown
    :icon="tabUser"
    flat
    dense
    :label="$q.screen.gt.sm ? (user ? user.name : $t('Account')) : undefined"
  >
    <template #label>
      <QBadge v-if="authStore.isImpersonating()" floating color="negative">
        IMP
      </QBadge>
    </template>
    <q-list>
      <template v-if="user">
        <q-item v-close-popup clickable :to="localePath('/profile/settings')">
          <q-item-section>
            <q-item-label>{{ $t('Settings') }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-close-popup clickable @click="authStore.logUserOut()">
          <q-item-section>
            <q-item-label>{{ $t('Sign out') }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
      <q-item v-else :to="localePath('/auth/login')">
        <q-item-section>
          <q-item-label>🚀 {{ $t('Register or Login') }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item v-if="authStore.isImpersonating()" v-close-popup clickable @click="authStore.unimpersonate()">
        <q-item-section>
          <q-item-label>{{ $t('UnImpersonate') }}: {{ user?.use_name }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>
